const getDefaultState = () => ({
  isAuthenticated: false,
  isLoading: false,
  jwt: null,
  headers: null,
  userId: null,
  session: null,
  settings: {
    toggleDataPolicy: false,
    toggleNotifications: false,
  },
});

export default {
  state: getDefaultState(),
  mutations: {

    setLoading(state, value) {
      state.isLoading = value;
    },

    resetAuthState(state) {
      Object.assign(state, getDefaultState());
    },

    loginUser(state, auth) {
      state.jwt = auth.login.jwt;
      state.userId = auth.login.user.id;
      state.headers = { Authorization: `Bearer ${auth.login.jwt}` };
      state.isAuthenticated = true;
    },

    loginUserFromSession(state, jwt) {
      state.headers = { Authorization: `Bearer ${jwt}` };
      state.jwt = jwt;
    },

    loginUserFromSessionTwo(state, data) {
      state.userId = data.me.id;
      state.isAuthenticated = true;
    },

    setSession(state, session) {
      state.session = session;
    },

  },
};
