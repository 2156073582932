const getDefaultState = () => ({
  glossary: null,
});

export default {
  state: getDefaultState(),
  mutations: {
    resetGlossaryState(state) {
      Object.assign(state, getDefaultState());
    },
  },
};
