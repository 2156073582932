const getDefaultState = () => ({
  currentCourse: null,
  currentCourseColor: null,
  currentCourseColor2: null,
  currentCourseColor3: null,
  currentCourseIndex: null,
  currentCourseID: null,
  currentCourseGlossaryID: null,
  currentClassId: null,
  currentSemester: 0,
  filterClassId: null,
  newNotification: false,
  incomingToggle: false,
});

export default {
  state: getDefaultState(),
  mutations: {
    resetSettingsState(state) {
      Object.assign(state, getDefaultState());
    },

    toggleNewNotification(state, value) {
      state.newNotification = value;
      state.incomingToggle = !state.incomingToggle;
    },

    setClassId(state, id) {
      state.currentClassId = id;
    },

    setCurrentSemester(state, semester) {
      state.currentSemester = semester;
    },

    setFilterClassId(state, id) {
      state.filterClassId = id;
    },

    setCurrentCourse(state, payload) {
      const { course } = payload;
      const { index } = payload;
      const { id } = payload;
      state.currentCourse = course.Bezeichnung;
      state.currentCourseGlossaryID = course?.glossar?.data?.id;
      state.currentCourseColor = course.Farbcode;
      state.currentCourseColor2 = course.Farbcode2;
      state.currentCourseColor3 = course.Farbcode3;
      state.currentCourseIndex = index;
      state.currentCourseID = id;
    },

  },
};
