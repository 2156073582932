<template>
  <transition @enter="fadeIn" @leave="fadeOut" :css="false" mode="out-in">
    <div v-if="showDarkBackground" class="w-full h-screen fixed top-0 inset-0 z-20 bg-black" @click="closeOverlay"></div>
  </transition>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';

// Mixins
import iosVibrations from '../mixins/iosVibrations.vue';

export default {
  name: 'darkBackground',
  mixins: [iosVibrations],

  computed: {
    showDarkBackground() {
      return this.$store.state.layout.showDarkBackground;
    },
  },

  methods: {
    closeOverlay() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', null);
    },

    fadeIn(el, done) {
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 250,
        opacity: [0, 0.5],
        complete: done,
      });
    },

    fadeOut(el, done) {
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 250,
        opacity: 0,
        complete: done,
      });
    },
  },

};
</script>
