<script>
export default {
  name: 'fcm',

  data() {
    return {
      fcm: null,
    };
  },

  computed: {
    updateUserDataFcm() {
      return this.$store.state.updateUserDataFcm;
    },
    userDataId() {
      return this.$store.state.profile?.me?.userData?.data?.id;
    },
  },

  watch: {
    fcm() {
      const { fcm, userDataId } = this;
      this.updateUserDataFcm({ userDataId, fcm }).then((result) => {
        if (result.error) {
          console.log(result.error.message);
        }
      });
    },
  },

  mounted() {
    window.addFcm = (token) => {
      this.addFcm(token);
    };
  },

  methods: {
    addFcm(token) {
      this.fcm = token;
    },

    getFCM() {
      console.log('getting fcm now..');
      if (window.webkit && window.webkit.messageHandlers) {
        window.webkit.messageHandlers.getFCM.postMessage('Getting FCM..');
      }

      if (window.Android) {
        window.Android.getFCM('Getting FCM..');
      } else {
        console.log('no android');
      }
    },
  },
};
</script>
