<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'queryMyExams',
  watch: {
    myExams() {
      this.$store.commit('setStrapiResult', { vuexModule: 'profile', state: 'myExams', data: this.myExams?.me });
    },
  },
  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'cache-and-network',
    });

    const queryMyExams = gql`
      query {
        me {
          userData {
            data {
              attributes {
                examProgress(pagination: { limit: 999 })  {
                  id
                  finished
                  passed
                  courseId
                  started
                  progress
                  totalCorrect
                  pruefungen {
                    data {
                      id
                      attributes {
                        Index
                      }
                    }
                  }
                }
              }
            }
          }
          pruefungen  {
                  data {
                    id
                    attributes {
                      kurs {
                        data {
                          id
                        }
                      }
                      publishedAt
                      Index
                      mc_fragens(pagination: { limit: 999 }) {
                        data {
                          attributes {
                            sumCorrect
                          }
                        }
                      }
                      availableForTraining
                      timePerQuestion
                      percentageToFinish
                      Kurzbeschreibung
                      vortest {
                        index
                        lernpfade {
                          data {
                            id
                          }
                        }
                      }
                    }
                  }
                }
          klassen {
            data {    
              attributes {
                Zuordnung {
                  ... on ComponentKlassenKurs {
                    Kurs {
                      data {
                        id
                      }
                    }
                  }
                  ... on ComponentKlassenPruefung {
                    pruefung(
                      filters: {pruefungen: {publishedAt: { ne: null }}}
                      pagination: {limit: 500})  {
                      start 
                      ende
                      id
                      pruefungen {
                        data {
                          id
                          attributes {
                            Index
                            mc_fragens(pagination: { limit: 999 }) {
                              data {
                                attributes {
                                  sumCorrect
                                }
                              }
                            }
                            availableForTraining
                            timePerQuestion
                            percentageToFinish
                            Kurzbeschreibung
                            vortest {
                              index
                              lernpfade {
                                data {
                                  id
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: queryMyExamsEx, data: myExams } = useQuery({
      query: queryMyExams,
      context,
      fetchOnMount: false,
    });
    store.commit('setStrapiExecutionHandler', { item: 'queryMyExamsEx', result: queryMyExamsEx });
    return { myExams };
  },
};
</script>
