const getDefaultState = () => ({
  selectedLernpfadId: null,
  selectedLernpfad: null,
  selectedLernspotId: null,
  selectedPfadaufgabeId: null,
  selectedPfadaufgabe: null,
  selectedMcFrageId: null,
  selectedMcFrage: null,
  savingProgress: false,
  selectedPlaceholder: 0,
  isFetchingSelectedLernpfad: false,
  currentSlide: 0,
  globalImageUrl: null,
  globalVideoUrl: null,
});

export default {
  state: getDefaultState(),
  getters: {
    currentLernspot(state) {
      const { lernspots } = state.selectedLernpfad.lernpfade.data.attributes;
      const lernspotIndex = lernspots.findIndex((spot) => spot.id === state.selectedLernspotId);
      return lernspots[lernspotIndex];
    },
    possibleAnswers: () => (pfadaufgabe) => {
      // const pfadaufgabe = state.selectedPfadaufgabe?.pfadaufgaben?.data?.attributes?.Aufgabentyp[0];
      if (pfadaufgabe) {
        const answers = [];
        // eslint-disable-next-line no-underscore-dangle
        const taskType = pfadaufgabe.__typename;
        if (taskType === 'ComponentAufgabenLueckentext') {
          const splittedAnswers = pfadaufgabe.content.split('</s>');
          splittedAnswers.pop();
          splittedAnswers.forEach((answer) => {
            const splitted = answer.split('<s>').pop();
            const parsed = new DOMParser().parseFromString(splitted, 'text/html');
            answers.push(parsed.documentElement.textContent);
          });
        } else if (taskType === 'ComponentAufgabenMcFrage') {
          return null;
        } else if (taskType === 'ComponentAufgabenBegriffspaare') {
          pfadaufgabe.Paar.forEach((paarAnswer) => {
            answers.push(paarAnswer.begriff1);
            answers.push(paarAnswer.begriff2);
          });
        } else if (taskType === 'ComponentAufgabenListenzuordnung') {
          pfadaufgabe.Liste.forEach((liste) => {
            const listAnswers = liste.Answers.split(';');
            listAnswers.forEach((answer) => {
              answers.push(answer);
            });
          });
        }

        // Mix with fake answers
        const dummies = [];
        if (pfadaufgabe?.dummies) {
          pfadaufgabe.dummies.forEach((dummie) => {
            dummies.push(dummie.answer);
          });
        }
        const possibleAnswers = answers.concat(dummies);
        const shuffledAnswers = possibleAnswers
          .map((value) => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value);
        return shuffledAnswers;
        // return answers;
      }
      return null;
    },
  },
  mutations: {

    setGlobalImageUrl(state, url) {
      state.globalImageUrl = url;
    },

    setGlobalVideoUrl(state, url) {
      state.globalVideoUrl = url;
    },

    setSaveIndicator(state) {
      state.savingProgress = true;
    },

    resetLearningState(state) {
      Object.assign(state, getDefaultState());
    },
    setLearningFetchingIndicator(state, payload) {
      state[`isFetching${payload.key}`] = payload.value;
    },
    resetLernpfad(state) {
      state.selectedLernpfad = null;
      state.selectedLernpfadId = null;
    },
    setSelectedLernpfadId(state, id) {
      state.selectedLernpfadId = id;
    },
    resetSelectedLernpfad(state) {
      state.selectedLernpfad = null;
    },
    setSelectedLernpfad(state, lernpfad) {
      state.selectedLernpfad = lernpfad;
    },
    setSelectedLernspotId(state, id) {
      state.selectedLernspotId = id;
    },
    setPfadaufgabe(state, pfadaufgabe) {
      state.selectedPfadaufgabe = pfadaufgabe;
    },
    setMcFrage(state, mcFrage) {
      state.selectedMcFrage = mcFrage;
    },
    setSelectedPfadaufgabeId(state, id) {
      state.selectedPfadaufgabeId = id;
    },
    setSelectedMcFrageId(state, id) {
      state.selectedMcFrageId = id;
    },
    resetAufgaben(state) {
      state.selectedPfadaufgabe = null;
      state.selectedMcFrage = null;
    },
    setSelectedPlaceholder(state, index) {
      state.selectedPlaceholder = index;
    },
    changeCurrentSlide(state, value) {
      if (value === 'increment') state.currentSlide += 1;
      if (value === 'reduce') state.currentSlide -= 1;
      if (typeof value === 'number') state.currentSlide = value;
    },
  },
  actions: {
    saveProgress({ state, rootState }, payload) {
      state.savingProgress = true;
      let saveToStrapi = false;
      let savePoints = false;
      const { slideId } = payload;
      const { currentMaxSlideId } = payload;
      const { finished } = payload;
      const { currentCourseID } = rootState.settings;
      const { id } = rootState.profile.me.userData.data;
      const { lernpfadPoints } = rootState.profile.me.userData.data.attributes;
      const { lernpfadProgress } = rootState.profile.me.userData.data.attributes;
      const lernpfadIndex = lernpfadProgress?.lernpfade.findIndex((lernpfad) => lernpfad.id === state.selectedLernpfadId);
      if (lernpfadIndex === -1) {
        saveToStrapi = true;
        lernpfadProgress.lernpfade.push({
          id: state.selectedLernpfadId,
          courseId: currentCourseID,
          lernspots: [{
            id: state.selectedLernspotId,
            finished,
            currentMaxSlideId,
            slides: [slideId],
          }],
        });
      } else {
        const lernspotIndex = lernpfadProgress?.lernpfade[lernpfadIndex]?.lernspots.findIndex((spot) => spot.id === state.selectedLernspotId);
        if (lernspotIndex === -1) {
          saveToStrapi = true;
          lernpfadProgress.lernpfade[lernpfadIndex].lernspots.push({
            id: state.selectedLernspotId,
            currentMaxSlideId,
            slides: [slideId],
          });
        } else {
          const slideIndex = lernpfadProgress?.lernpfade[lernpfadIndex]?.lernspots[lernspotIndex]?.slides.findIndex((slide) => slide === slideId);
          if (slideIndex === -1) {
            saveToStrapi = true;
            lernpfadProgress.lernpfade[lernpfadIndex].lernspots[lernspotIndex].slides.push(slideId);
            lernpfadProgress.lernpfade[lernpfadIndex].lernspots[lernspotIndex].currentMaxSlideId = currentMaxSlideId;
          }
          if (finished) {
            if (!lernpfadProgress.lernpfade[lernpfadIndex].lernspots[lernspotIndex].finished) {
              lernpfadProgress.lernpfade[lernpfadIndex].lernspots[lernspotIndex].finished = true;
              saveToStrapi = true;
              savePoints = true;
            }
          }
        }
      }

      if (saveToStrapi) {
        rootState.updateUserDataLernpfadProgress({ id, lernpfadProgress }).then((result) => {
          if (result.error) {
            console.log(result.error.message);
            state.savingProgress = false;
          } else {
            rootState.queryMeEx().then((result2) => {
              if (result2.error) {
                console.log(result2.error.message);
                state.savingProgress = false;
              } else {
                state.savingProgress = false;
              }
            });
          }
        });
      } else {
        state.savingProgress = false;
      }

      if (savePoints) {
        rootState.updateUserDataLernpfadPoints({ id, lernpfadPoints: lernpfadPoints + 1 }).then((result) => {
          if (result.error) {
            console.log(result.error.message);
          } else {
            rootState.queryMeEx();
          }
        });
      }
    },
  },
};
