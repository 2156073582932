<script>
import anime from 'animejs/lib/anime.es.js';

export default {
  name: 'fadeInOut',
  methods: {
    fadeIn(el, done) {
      anime({
        targets: el,
        easing: 'easeInSine',
        duration: 150,
        opacity: [0, 1],
        complete: done,
      });
    },
    fadeOut(el, done) {
      anime({
        targets: el,
        easing: 'easeInSine',
        duration: 50,
        opacity: [1, 0],
        complete: done,
      });
    },
  },
};
</script>
