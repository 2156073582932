const getDefaultState = () => ({
  me: null,
  today: new Date().toISOString(),
  selectedList: null,
});

export default {
  state: getDefaultState(),
  mutations: {
    resetProfileState(state) {
      Object.assign(state, getDefaultState());
    },

    setSelectedList(state, list) {
      state.selectedList = list;
    },
  },
  getters: {
    listEntries: (state) => (type) => {
      const selectedListId = state.selectedList?.id;
      if (selectedListId && type) {
        const { myLists } = state.me.userData.data.attributes;
        const listIndex = myLists.findIndex((list) => list.id === selectedListId);
        const lernspots = [];
        myLists[listIndex][type].ids.forEach((id) => {
          if (!lernspots.includes(id)) lernspots.push(id);
        });
        return lernspots;
      }
    },

    availableLernpfade: (state) => (currentCourseId) => {
      const lernpfade = [];
      const lernpfadeIds = [];
      state.me.klassen.data.forEach((klasse) => {
        klasse.attributes.Zuordnung.forEach((kurs) => {
          if (kurs?.Kurs?.data?.id === currentCourseId) {
            kurs.Lernpfade.forEach((lernpfad) => {
              if (!lernpfadeIds.includes(lernpfad.Lernpfad.data.id)) {
                lernpfadeIds.push(lernpfad.Lernpfad.data.id);
                lernpfade.push(lernpfad);
              }
            });
            // lernpfade = kurs.Lernpfade;
          }
        });
      });
      return lernpfade;
    },
  },
};
