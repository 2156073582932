<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"
      :class="{'transition duration-300 ease-in-out text-new-disabled' : color === 'grey', 'transition duration-300 ease-in-out text-new-black' : color === 'black'}">
    <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
  </svg>
</template>

<script>
export default {
  name: 'testSVG',
  props: ['color'],
};
</script>
