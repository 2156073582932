<template>
  <svg v-if="appName === 'HAFN'" class="h-8 w-8" viewBox="0 0 83 83" xmlns="http://www.w3.org/2000/svg" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g>
        <path d="M4.007,25.099C4.007,25.099 -3.167,30.122 1.856,37.297L6.746,44.28C6.746,44.28 11.77,51.455 18.944,46.431L25.927,41.542C25.927,41.542 33.102,36.518 28.078,29.343L23.189,22.36C23.189,22.36 18.165,15.185 10.99,20.209L4.007,25.099Z" style="fill:rgb(191,20,30);fill-rule:nonzero;"/>
        <path d="M20.774,53.419C20.774,53.419 12.1,52.2 10.881,60.874L9.408,71.355C9.408,71.355 8.189,80.028 16.863,81.247L27.343,82.72C27.343,82.72 36.017,83.938 37.236,75.266L38.709,64.785C38.709,64.785 39.928,56.111 31.254,54.892L20.774,53.419Z" style="fill:rgb(244,154,0);fill-rule:nonzero;"/>
        <path d="M37.98,23.4C37.98,23.4 33.734,31.06 41.394,35.307L59.188,45.17C59.188,45.17 66.849,49.416 71.095,41.756L80.959,23.962C80.959,23.962 85.205,16.301 77.545,12.055L59.751,2.191C59.751,2.191 52.09,-2.055 47.843,5.605L37.98,23.4Z" style="fill:rgb(0,121,60);fill-rule:nonzero;"/>
        <path d="M54.5,51.572C54.5,51.572 45.762,52.183 46.373,60.92L47.243,73.365C47.243,73.365 47.854,82.103 56.592,81.492L69.037,80.622C69.037,80.622 77.775,80.011 77.164,71.273L76.293,58.828C76.293,58.828 75.682,50.09 66.945,50.701L54.5,51.572Z" style="fill:rgb(14,47,111);fill-rule:nonzero;"/>
    </g>
</svg>
<svg v-else class="h-8 w-8" viewBox="0 0 207 293" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1,0,0,1,-35.4313,-35.4338)">
        <g transform="matrix(4.16667,0,0,4.16667,0,0)">
            <g transform="matrix(1,0,0,1,23.6035,8.5041)">
                <path d="M0,70.158L-15.1,70.158L-15.1,0L34.508,0L34.508,70.158L17.632,70.158C17.632,70.158 15.989,62.448 18.074,61.435C20.16,60.425 22.435,60.615 23.825,60.487C25.215,60.361 27.238,59.287 27.301,58.022C27.365,56.76 25.847,54.736 25.974,54.104C26.1,53.473 28.059,52.776 27.301,51.009C26.543,49.239 25.847,48.543 26.859,47.721C27.87,46.9 29.133,46.142 27.365,43.046C25.595,39.949 23.888,37.23 24.394,31.732C24.9,26.234 14.038,22.254 9.515,22.254C4.993,22.254 -8.848,23.517 -8.848,38.622C-8.848,47.533 0.253,54.864 0.632,55.875C1.011,56.886 0.885,58.909 0.632,61.941C0.379,64.974 -0.063,67.882 0,70.158" style="fill:rgb(176,162,149);fill-rule:nonzero;"/>
            </g>
        </g>
        <g transform="matrix(4.16667,0,0,4.16667,0,0)">
            <g transform="matrix(1,0,0,1,34.0806,48.99)">
                <path d="M0,8.135C0,8.135 2.817,-0.987 13.436,-0.05C13.436,-0.05 11.178,9.122 0,8.135" style="fill:rgb(176,162,149);fill-rule:nonzero;"/>
            </g>
        </g>
        <g transform="matrix(4.16667,0,0,4.16667,0,0)">
            <g transform="matrix(1,0,0,1,31.3818,48.99)">
                <path d="M0,8.135C0,8.135 -2.818,-0.987 -13.437,-0.05C-13.437,-0.05 -11.178,9.122 0,8.135" style="fill:rgb(176,162,149);fill-rule:nonzero;"/>
            </g>
        </g>
        <g transform="matrix(4.16667,0,0,4.16667,0,0)">
            <g transform="matrix(1,0,0,1,32.7871,78.6622)">
                <path d="M0,-43.129C-7.026,-33.596 -2.704,-25.346 -0.781,-22.525L-0.781,0L0.752,0L0.752,-22.561C8.632,-34.441 0,-43.129 0,-43.129" style="fill:rgb(176,162,149);fill-rule:nonzero;"/>
            </g>
        </g>
    </g>
</svg>

</template>

<script>
export default {
  name: 'hafnSVG',
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
    };
  },
};
</script>
