<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'updateUserDataFirstLoginMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const updateUserDataFirstLoginMutation = gql`
      mutation($userDataId: ID!, $nickname: String!) {
        updateUserData(id: $userDataId, data: {
          nickname: $nickname,
          datenschutzVersion: null,
          currentStreak: 0,
          longestStreak: 0,
          initalStreakDay: null,
          streakJoker: 3,
          firstLogin: false,
          recentTrainings: [],
          lernpfadProgress: { lernpfade: [] }
          wrongPlayedQuestions: { ids: [] }
          recentPlayedQuestions: { ids: [] }
          totalPlayedQuestions: { ids: [] }
          wrongPlayedExercise: { ids: [] }
          recentPlayedExercise: { ids: [] }
          totalPlayedExercises: { ids: [] }
          examProgress: []
          lastLernpfade: []
          onboardingCompleted: false,
          notifications: [],
          myLists: [
            { Bezeichnung: "Kann ich", lernpfade: { ids: [] }, mcFragen: { ids: [] }, pfadaufgaben: { ids: [] }}
            { Bezeichnung: "Kann ich nicht", lernpfade: { ids: [] }, mcFragen: { ids: [] }, pfadaufgaben: { ids: [] }}
            { Bezeichnung: "Merken", lernpfade: { ids: [] }, mcFragen: { ids: [] }, pfadaufgaben: { ids: [] }}
          ]
          points: {
            battlePoints: [],
            mcTrainingPoints: [],
            pfadaufgabenPoints: []
          }
        }) {
          data {
            id
          }
        }
      }
      `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: updateUserDataFirstLogin } = useMutation(updateUserDataFirstLoginMutation, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'updateUserDataFirstLogin', result: updateUserDataFirstLogin });
  },
};
</script>
