import { createStore } from 'vuex';
import auth from '../modules/login/store/auth.js';
import settings from '../modules/navbar/store/settings.js';
import layout from '../modules/navbar/store/layout.js';
import glossary from '../modules/glossary/store/glossary.js';
import learning from '../modules/learning/store/learning.js';
import profile from '../modules/profile/store/profile.js';
import training from '../modules/training/store/training.js';
import ranking from '../modules/ranking/store/ranking.js';
import exam from '../modules/exam/store/exam.js';
import dashboard from '../modules/dashboard/store/dashboard.js';

export default createStore({
  modules: {
    auth,
    settings,
    layout,
    profile,
    learning,
    training,
    glossary,
    ranking,
    exam,
    dashboard,
  },
  mutations: {

    setStrapiExecutionHandler(state, payload) {
      state[payload.item] = payload.result;
    },

    setStrapiResult(state, payload) {
      state[payload.vuexModule][payload.state] = payload.data;
    },

    addToggle(state, payload) {
      if (payload?.storeContainer) {
        const { storeID } = payload;
        const { storeContainer } = payload;
        const { storeWrapper } = payload;
        const { value } = payload;
        state[storeContainer][storeWrapper][storeID] = value;
        if (storeID === 'questions' && state[storeContainer][storeWrapper].limitedQuestions) {
          state[storeContainer][storeWrapper].limitFilter = { limit: parseInt(value, 10) };
        }
      }
    },

    addSelector(state, payload) {
      const { storeContainer } = payload;
      const { storeWrapper } = payload;
      const { storeID } = payload;
      const { value } = payload;
      const { type } = payload;
      const { isArray } = payload;

      if (isArray) {
        value.forEach((innerValue) => {
          this.commit('addSelectorValue', {
            type, storeContainer, storeWrapper, storeID, value: innerValue,
          });
        });
      } else {
        this.commit('addSelectorValue', {
          type, storeContainer, storeWrapper, storeID, value,
        });
      }
    },

    addSelectorValue(state, payload) {
      const { storeContainer } = payload;
      const { storeWrapper } = payload;
      const { storeID } = payload;
      const { value } = payload;
      const { type } = payload;

      if (type === 'replace') {
        if (storeWrapper) {
          state[storeContainer][storeWrapper][storeID] = value;
        } else {
          state[storeContainer][storeID] = value;
        }
      } else if (state[storeContainer][storeWrapper][storeID].includes(value)) {
        const index = state[storeContainer][storeWrapper][storeID].indexOf(value);
        state[storeContainer][storeWrapper][storeID].splice(index, 1);
        // add graphQL Filter
        this.commit('addFilter', {
          storeContainer, storeWrapper,
        });
      } else {
        state[storeContainer][storeWrapper][storeID].push(value);
        // add graphQL Filter
        this.commit('addFilter', {
          storeContainer, storeWrapper,
        });
      }
    },

    manipulatePfadaufgabenFilter(state, ids) {
      state.training.settings.staticSubjectsID = ids;
      this.commit('addFilter', { storeContainer: 'training', storeWrapper: 'settings' });
    },

    addFilter(state, payload) {
      const { storeContainer, storeWrapper } = payload;
      const {
        tagsID, staticSubjectsID, subjectsID, examsID, listsID, wrongPlayedID, recentPlayedID, totalPlayedID,
      } = state[storeContainer][storeWrapper];
      const arraySum = recentPlayedID.concat(totalPlayedID);
      let idFilter = null;
      if (arraySum.length > 0 && listsID.length === 0 && wrongPlayedID.length === 0) {
        idFilter = { id: { notIn: arraySum } };
      } else if (listsID.length > 0 || wrongPlayedID.length > 0) {
        idFilter = { and: { id: { notIn: arraySum } }, id: { in: [...wrongPlayedID, ...listsID] } };
      }
      const { type } = state.training.currentTraining;
      state[storeContainer][storeWrapper].filter = {
        ...(type !== 'Pfadaufgabe' && { pruefungen: { availableForTraining: { ne: false } } }),
        ...(tagsID.length > 0 && { tags: { id: { in: tagsID } } }),
        ...(staticSubjectsID && subjectsID.length === 0 && { lernpfade: { id: { in: staticSubjectsID } } }),
        ...(subjectsID.length > 0 && { lernpfade: { id: { in: subjectsID } } }),
        ...(examsID.length > 0 && { pruefungen: { availableForTraining: { ne: false }, id: { in: examsID } } }),
        ...(idFilter !== null && idFilter),
        ...{ kurs: { id: { eq: state.settings.currentCourseID } } },
      };
    },
  },
  actions: {
    logoutUser(context) {
      window.localStorage.removeItem('app');
      context.commit('resetAuthState');
    },

    resetStates(context) {
      console.log('restting all');
      context.commit('resetSettingsState');
      // context.commit('resetLayoutState');
      context.commit('resetLearningState');
      context.commit('resetTrainingState');
      context.commit('resetGlossaryState');
      context.commit('resetRankingState');
      context.commit('resetExamState');
      context.commit('resetDashboardState');
      context.commit('resetProfileState');
    },
  },
});
