<script>
export default {
  name: 'detectNotch',

  computed: {
    sat() {
      return getComputedStyle(document.documentElement).getPropertyValue('--sat');
    },
  },

};
</script>

<style>
:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
}
</style>
