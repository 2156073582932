<script>
import { io } from 'socket.io-client';

export default {
  name: 'sockets',

  data() {
    return {
      socket: null,
    };
  },

  computed: {
    queryMeEx() {
      return this.$store.state.queryMeEx;
    },
    queryMyBattlesEx() {
      return this.$store.state.queryMyBattlesEx;
    },
    userDataId() {
      return this.$store.state.profile?.me?.userData?.data?.id;
    },
    updateCurrentSocket() {
      return this.$store.state.updateCurrentSocket;
    },
    incomingToggle() {
      return this.$store.state.settings.incomingToggle;
    },
  },

  watch: {
    userDataId() {
      console.log('init');
      if (this.userDataId && !this.socket) this.connectSocket();
    },
    incomingToggle() {
      console.log('incoming');
      this.queryMyBattlesEx();
      this.queryMeEx();
    },
  },

  methods: {
    connectSocket() {
      if (!this.socket) {
        this.socket = io(process.env.VUE_APP_SOCKET, {
          path: '/socket.io',
        });
      }
      this.socket.on('connect', () => {
        console.log(`%cConnected to -> ${this.socket.id}`, 'color:green');
        this.updateCurrentSocket({ userDataId: this.userDataId, currentSocket: this.socket.id });
      });

      this.socket.on('newNotification', () => {
        console.log('new notification incoming');
        this.$store.commit('toggleNewNotification', true);
        this.refreshUserData();
      });
    },

    refreshUserData() {
      console.log('refreshing..');
    },
  },
};
</script>
