<template>
  <div class="w-full p-3 flex flex-row justify-between items-center text-new-black font-visby cursor-pointer" :class="{'border-b border-new-yellow' : border, 'bg-new-black' : selected?.includes(label)}" @click="toggle">
    <div class="flex-grow pr-2 flex flex-row justify-between items-center">
      <div class="text-lg" :class="{'text-white' : selected?.includes(label)}">{{label}}</div>
      <checkSVG v-if="selected?.includes(label)" />
    </div>
  </div>
</template>

<script>
// Mixins
import iosVibrations from '../mixins/iosVibrations.vue';

// SVG
import checkSVG from '../assets/svg/check.vue';

export default {
  name: 'selector',
  props: ['label', 'border', 'storeContainer', 'storeWrapper', 'storeID', 'storeID2', 'storeID3', 'storeID4', 'storeID5', 'storeID6', 'storeID7', 'value2', 'value3', 'value4', 'value5', 'value6', 'value7', 'type', 'isArray'],
  mixins: [iosVibrations],
  components: { checkSVG },

  computed: {
    selected() {
      if (this.storeWrapper) {
        return this.$store.state[this.storeContainer][this.storeWrapper][this.storeID];
      }
      return this.$store.state[this.storeContainer][this.storeID];
    },
  },

  methods: {
    toggle() {
      this.iosVibrate(1);
      if (this.storeID === 'exams' || this.storeID === 'subjects') {
        this.$store.commit('resetTagFilter');
      }
      this.$store.commit('addSelector', {
        storeContainer: this.storeContainer, storeWrapper: this.storeWrapper, storeID: this.storeID, value: this.label, type: this.type,
      });
      if (this.storeID2 && this.value2) {
        this.$store.commit('addSelector', {
          storeContainer: this.storeContainer, storeWrapper: this.storeWrapper, storeID: this.storeID2, value: this.value2, type: this.type, isArray: this.isArray,
        });
      }
      if (this.storeID3 && this.value3 !== null) {
        this.$store.commit('addSelector', {
          storeContainer: this.storeContainer, storeWrapper: this.storeWrapper, storeID: this.storeID3, value: this.value3, type: this.type,
        });
      }
      if (this.storeID4 && this.value4 !== null) {
        this.$store.commit('addSelector', {
          storeContainer: this.storeContainer, storeWrapper: this.storeWrapper, storeID: this.storeID4, value: this.value4, type: this.type,
        });
      }
      if (this.storeID5 && this.value5 !== null) {
        this.$store.commit('addSelector', {
          storeContainer: this.storeContainer, storeWrapper: this.storeWrapper, storeID: this.storeID5, value: this.value5, type: this.type,
        });
      }
      if (this.storeID6 && this.value6 !== null) {
        this.$store.commit('addSelector', {
          storeContainer: this.storeContainer, storeWrapper: this.storeWrapper, storeID: this.storeID6, value: this.value6, type: this.type,
        });
      }
      if (this.storeID7 && this.value7 !== null) {
        this.$store.commit('addSelector', {
          storeContainer: this.storeContainer, storeWrapper: this.storeWrapper, storeID: this.storeID7, value: this.value7, type: this.type,
        });
      }
    },
  },
};
</script>
