const getDefaultState = () => ({
  lernpfadPreviewIds: [],
  lernpfadPreview: null,
  listLernspots: [],
  listMc: [],
  listPfadaufgaben: [],
});

export default {
  state: getDefaultState(),
  mutations: {

    setListLernspots(state, listLernspots) {
      state.listLernspots = listLernspots;
    },

    setListPfadaufgaben(state, pfadaufgaben) {
      state.listPfadaufgaben = pfadaufgaben;
    },

    setListMc(state, mc) {
      state.listMc = mc;
    },

    resetDashboardState(state) {
      Object.assign(state, getDefaultState());
    },
    setLernpfadPreview(state, preview) {
      state.lernpfadPreview = preview;
    },

    setLernpfadIds(state, ids) {
      state.lernpfadPreviewIds = ids;
    },

    resetLernpfadIds(state) {
      state.lernpfadPreviewIds = [];
      state.lernpfadPreview = null;
    },
  },

};
