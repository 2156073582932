<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'queryUserId',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'network-only',
    });

    const queryUserId = gql`
      query {
        me {
          id
        }
      }
    `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: queryUserIdEx } = useQuery({
      query: queryUserId,
      context,
      fetchOnMount: false,
    });
    store.commit('setStrapiExecutionHandler', { item: 'queryUserIdEx', result: queryUserIdEx });
  },
};
</script>
