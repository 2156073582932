const getDefaultState = () => ({
  scrollDown: false,
  showOverlay: null,
  showSecondOverlay: null,
  showDarkBackground: false,
  showDarkBackground2: false,
  showInfoOverlay: false,
  showInfoOverlayIndex: 0,
  searchString: '',
});

export default {
  state: getDefaultState(),
  mutations: {
    resetLayoutState(state) {
      Object.assign(state, getDefaultState());
    },

    setSearchString(state, value) {
      state.searchString = value;
    },

    toggleScrollDown(state, value) {
      state.scrollDown = value;
    },

    toggleInfoOverlay(state, payload) {
      const { value } = payload;
      const { index } = payload;
      const { level } = payload;
      state.showInfoOverlay = value;
      state.showInfoOverlayIndex = index;
      if (level === 2) {
        state.showDarkBackground2 = value;
      } else {
        state.showDarkBackground = value;
      }
    },

    showOverlay(state, value) {
      state.showOverlay = value;
      state.showDarkBackground = value !== null;
    },

    showBlankOverlay(state, value) {
      state.showOverlay = value;
    },

    showDarkBackground(state, value) {
      state.showDarkBackground = value;
    },

    showSecondOverlay(state, value) {
      state.showSecondOverlay = value;
      if (value !== null) {
        state.showDarkBackground2 = true;
      } else {
        state.showDarkBackground2 = false;
      }
    },

  },
};
