<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'updateLastLernpfadMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const updateLastLernpfadMutation = gql`
      mutation($userDataId: ID!, $lastLernpfade: JSON!) {
        updateUserData(id: $userDataId, data: { lastLernpfade: $lastLernpfade }) {
          data {
            id
          }
        }
      }
      `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: updateLastLernpfad } = useMutation(updateLastLernpfadMutation, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'updateLastLernpfad', result: updateLastLernpfad });
  },
};
</script>
