<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'queryVortestId',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'cache-first',
    });

    const queryVortestId = gql`
    query($lernpfadIds: [ID!], $vortestIndices: [String!]) {
      lernpfades(filters: {id: {in: $lernpfadIds}}) {
        data {
          id
          attributes {
            Bezeichnung
            lernspots(filters: {vortestIndex: {in: $vortestIndices}}) {
              id
              Bezeichnung
              vortestIndex
            }
          }
        }
      }
    }
    `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: queryVortestEx } = useMutation(queryVortestId, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'queryVortestEx', result: queryVortestEx });
  },
};
</script>
