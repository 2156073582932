<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';

export default {
  name: 'queryDataPolicy',
  watch: {
    dataPolicy() {
      this.$store.commit('setStrapiResult', { vuexModule: 'auth', state: 'dataPolicy', data: this.dataPolicy.datenschutz });
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'network-only',
    });

    const queryDataPolicy = gql`
      query {
        datenschutz {
          data {
            attributes {
              Version
              Text
            }
          }
        }
      }
    `;

    const { data: dataPolicy } = useQuery({
      query: queryDataPolicy,
    });
    return { dataPolicy };
  },
};
</script>
