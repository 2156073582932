<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'queryMyBattles',
  watch: {
    myBattles() {
      this.$store.commit('setStrapiResult', { vuexModule: 'profile', state: 'myBattles', data: this?.myBattles?.me?.userData?.data?.attributes });
    },
  },
  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'cache-and-network',
    });

    const queryMyBattles = gql`
    query($courseId: ID!) {
      me {
        userData {
          data {
            attributes {
              battleInvites(filters: { course: { id: { eq: $courseId } } }) {
                data {
                  id
                  attributes {
                    createdAt
                    public
                    started
                    settings
                    battleRound { round }
                    creatorProgress
                    creatorResultsSeen
                    opponentResultsSeen
                    opponentProgress
                    creator {
                      data {
                        id
                        attributes {
                          nickname
                          avatar { data {attributes {url}}}
                        }
                      }
                    }
                    opponent {
                      data {
                        id
                        attributes {
                          nickname
                          avatar { data {attributes {url}}}
                        }
                      }
                    }
                  }
                }
              }
              pendingBattles(filters: { course: { id: { eq: $courseId } } }) {
                data {
                  id
                  attributes {
                    createdAt
                    public
                    settings
                    battleRound { round }
                    creatorProgress
                    opponentProgress
                    creatorResultsSeen
                    opponentResultsSeen
                    started
                    creator {
                      data {
                        id
                        attributes {
                          nickname
                          avatar { data {attributes {url}}}
                        }
                      }
                    }
                    opponent {
                      data {
                        id
                        attributes {
                          nickname
                          avatar { data {attributes {url}}}
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const variables = reactive({
      courseId: computed(() => store.state.settings.currentCourseID),
    });

    const { execute: queryMyBattlesEx, data: myBattles } = useQuery({
      query: queryMyBattles,
      context,
      variables,
      fetchOnMount: false,
    });

    store.commit('setStrapiExecutionHandler', { item: 'queryMyBattlesEx', result: queryMyBattlesEx });

    return { myBattles };
  },
};
</script>
