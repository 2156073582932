<script>
import anime from 'animejs/lib/anime.es.js';

export default {
  name: 'slideInOutBottom',

  data() {
    return {
      mobile: true,
    };
  },

  mounted() {
    if (document.documentElement.clientWidth >= 786) {
      this.mobile = false;
    }
  },

  methods: {
    hideCoursePicker() {
      this.$store.commit('toggleCoursePicker', false);
    },

    slideInBottom(el, done) {
      if (!this.mobile) {
        anime({
          targets: el,
          easing: 'easeInOutCubic',
          duration: 250,
          opacity: [0, 100],
          complete: done,
        });
      } else {
        anime({
          targets: el,
          easing: 'easeInOutCubic',
          duration: 250,
          translateY: ['100%', 0],
          complete: done,
        });
      }
    },

    slideOutBottom(el, done) {
      if (!this.mobile) {
        anime({
          targets: el,
          easing: 'easeInOutCubic',
          duration: 250,
          opacity: [100, 0],
          complete: done,
        });
      } else {
        anime({
          targets: el,
          easing: 'easeInOutCubic',
          duration: 250,
          translateY: [0, '100%'],
          complete: done,
        });
      }
    },
  },
};
</script>
