<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" :class="{'text-green-500' : color === 'green', 'text-new-black' : color === 'black'}" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
</svg>
</template>

<script>
export default {
  name: 'checkSVG',
  props: ['color'],
};
</script>
