<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'updateUserDataCompleteOnboardingMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const updateUserDataCompleteOnboardingMutation = gql`
      mutation($userDataId: ID!) {
        updateUserData(id: $userDataId, data: { 
          onboardingCompleted: true,
         }) {
          data {
            id
          }
        }
      }
      `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: updateUserDataCompleteOnboarding } = useMutation(updateUserDataCompleteOnboardingMutation, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'updateUserDataCompleteOnboarding', result: updateUserDataCompleteOnboarding });
  },
};
</script>
