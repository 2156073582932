import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

const privacy = () => import('../modules/privacy/privacy.vue');

const login = () => import('../modules/login/login.vue');
const onboarding = () => import('../modules/login/onboarding.vue');

const dashboard = () => import('../modules/dashboard/dashboard.vue');
const profile = () => import('../modules/profile/profile.vue');
const notifications = () => import('../modules/notifications/notifications.vue');
const ranking = () => import('../modules/ranking/ranking.vue');
const glossary = () => import('../modules/glossary/glossary.vue');

const learning = () => import('../modules/learning/learning.vue');
const lernpfadMobile = () => import('../modules/learning/views/lernpfadMobile.vue');
const lernspot = () => import('../modules/learning/views/lernspot.vue');

const training = () => import('../modules/training/training.vue');
const questions = () => import('../modules/training/views/questions.vue');
const exercises = () => import('../modules/training/views/exercises.vue');

const exam = () => import('../modules/exam/exam.vue');
const currentExam = () => import('../modules/exam/views/currentExam.vue');

const routes = [
  {
    path: '/datenschutz',
    name: 'privacy',
    component: privacy,
    meta: { requiresAuth: false, showNav: false },
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: { requiresAuth: false, showNav: false },
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: onboarding,
    meta: { requiresAuth: true, showNav: false },
  },
  {
    path: '/',
    name: 'dashboard',
    component: dashboard,
    meta: { requiresAuth: true, showNav: true },
  },
  {
    path: '/profile',
    name: 'profile',
    component: profile,
    meta: { requiresAuth: true, showNav: true },
  },
  {
    path: '/glossary',
    name: 'glossary',
    component: glossary,
    meta: { requiresAuth: true, showNav: true, searchBar: true },
  },
  {
    path: '/profile/notifications',
    name: 'notifications',
    component: notifications,
    meta: { requiresAuth: true, showNav: true },
  },
  {
    path: '/profile/ranking',
    name: 'ranking',
    component: ranking,
    meta: { requiresAuth: true, showNav: true },
  },
  {
    path: '/learning',
    name: 'learning',
    component: learning,
    meta: { requiresAuth: true, showNav: true, transparentNav: true },
  },
  {
    path: '/learning/lernpfad',
    name: 'lernpfadMobile',
    component: lernpfadMobile,
    meta: { requiresAuth: true, showNav: false },
  },
  {
    path: '/lernspot',
    name: 'lernspot',
    component: lernspot,
    meta: { requiresAuth: true, showNav: true, navType: 'clean' },
  },
  {
    path: '/training',
    name: 'training',
    component: training,
    meta: { requiresAuth: true, showNav: true },
  },
  {
    path: '/training/exercises',
    name: 'exercises',
    component: exercises,
    meta: { requiresAuth: true, showNav: true, navType: 'clean' },
  },
  {
    path: '/training/mc-trainer/questions',
    name: 'questions',
    component: questions,
    meta: { requiresAuth: true, showNav: true, navType: 'clean' },
  },
  {
    path: '/exam/current',
    name: 'currentExam',
    component: currentExam,
    meta: { requiresAuth: true, showNav: true, navType: 'clean' },
  },
  {
    path: '/exam',
    name: 'exam',
    component: exam,
    meta: { requiresAuth: true, showNav: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!store.state.auth.isAuthenticated) {
      next({ replace: false, name: 'login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  if (to.name === 'login') store.dispatch('resetStates');
});

export default router;
