const getDefaultState = () => ({
  ranking: null,
});

export default {
  state: getDefaultState(),
  mutations: {
    resetRankingState(state) {
      Object.assign(state, getDefaultState());
    },
  },
};
