const getDefaultState = () => ({
  exam: null,
  mode: null,
  questions: null,
  currentProgress: [],
  finished: false,
  totalCorrect: 0,
});

export default {
  state: getDefaultState(),
  getters: {
    currentExamProgress: (state) => (questionId) => {
      const progressIndex = state.currentProgress.data.findIndex((question) => question.questionId === questionId);
      return state.currentProgress.data[progressIndex];
    },
    currentlySelectedExamAnswers: (state) => (questionId) => {
      const progressIndex = state.currentProgress.data.findIndex((question) => question.questionId === questionId);
      return state.currentProgress.data[progressIndex].selectedAnswersIds.length;
    },
  },
  mutations: {
    resetExamState(state) {
      Object.assign(state, getDefaultState());
    },
    startExam(state, { exam, questions, mode }) {
      state.exam = exam;
      state.questions = questions;
      state.mode = mode;
      state.finished = false;
      state.totalCorrect = 0;
      state.currentProgress = { data: [] };
      state.questions.forEach((question) => {
        state.currentProgress.data.push({
          questionId: question.id,
          selectedAnswersIds: [],
          correctAnswersIds: [],
          submitted: false,
          result: null,
        });
      });
    },

    continueExam(state, {
      exam, questions, currentProgess, mode,
    }) {
      state.exam = exam;
      state.questions = questions;
      state.mode = mode;
      state.currentProgress = currentProgess;
    },

    changeMode(state, mode) {
      state.mode = mode;
    },

    setExamAnswer(state, { answerId, questionId }) {
      const progressIndex = state.currentProgress.data.findIndex((question) => question.questionId === questionId);
      const selectedAnswers = state.currentProgress.data[progressIndex].selectedAnswersIds;
      if (selectedAnswers.includes(answerId)) {
        const index = selectedAnswers.indexOf(answerId);
        selectedAnswers.splice(index, 1);
      } else {
        selectedAnswers.push(answerId);
      }
    },

    finishExam(state) {
      state.finished = true;
    },

    setExamQuestionResult(state, { result, questionId, correctAnswersIds }) {
      const progressIndex = state.currentProgress.data.findIndex((question) => question.questionId === questionId);
      state.currentProgress.data[progressIndex].result = result;
      state.currentProgress.data[progressIndex].correctAnswersIds = correctAnswersIds;
      state.currentProgress.data[progressIndex].submitted = true;
    },

    totalExamCorrect(state) {
      state.totalCorrect += 1;
    },
  },
};
