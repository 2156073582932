<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'updateUserDataRecentPlayedExercisesMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const updateUserDataRecentPlayedExercisesMutation = gql`
      mutation($id: ID!, $recentPlayedExercises: JSON!, $totalPlayedExercises: JSON!) {
        updateUserData(
          id: $id
          data: {
            recentPlayedExercise: $recentPlayedExercises
            totalPlayedExercises: $totalPlayedExercises
          }
        ) {
          data {
            id
          }
        }
      }
      `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: updateUserDataRecentPlayedExercises } = useMutation(updateUserDataRecentPlayedExercisesMutation, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'updateUserDataRecentPlayedExercises', result: updateUserDataRecentPlayedExercises });
  },
};
</script>
