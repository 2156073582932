<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'updateUserDataLernpfadProgress',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const updateUserDataLernpfadProgressMutation = gql`
      mutation($id: ID!, $lernpfadProgress: JSON!) {
        updateUserData(id: $id, data: { lernpfadProgress: $lernpfadProgress }) {
          data {
            id
          }
        }
      }
      `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: updateUserDataLernpfadProgress } = useMutation(updateUserDataLernpfadProgressMutation, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'updateUserDataLernpfadProgress', result: updateUserDataLernpfadProgress });
  },
};
</script>
