<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'querySelectedLernpfad',
  watch: {
    selectedLernpfad() {
      this.$store.commit('setSelectedLernpfad', this.selectedLernpfad);
    },
    isFetching() {
      this.$store.commit('setLearningFetchingIndicator', { key: 'SelectedLernpfad', value: this.isFetching });
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'cache-first',
    });

    const store = useStore();

    const querySelectedLernpfad = gql`
      query($id: ID) {
        lernpfade(id: $id) {
          data {
            attributes {
              Bezeichnung
              Kurzbeschreibung
              videolink
              Zeitaufwand
              titelbild {
                data {
                  attributes {
                    url
                  }
                }
              }
              lernspots(pagination: {limit: 500}) {
                id
                Filter
                bgColor
                Bezeichnung
                finishedText
                Kurzbeschreibung
                GlobalesBild {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                globalesVideo
                Titelbild {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                slides(pagination: {limit: 500}) {
                  Titel
                  Typ
                  pfadaufgabe {
                    data {
                      id
                    }
                  }
                  mc_frage {
                    data {
                      id
                    }
                  }
                  id
                  content
                }
              }
              download {
                Datei {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                Bezeichnung
              }
            }
          }
        }
      }
    `;

    const variables = reactive({
      id: computed(() => store.state.learning.selectedLernpfadId),
    });

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { data: selectedLernpfad, isFetching } = useQuery({
      query: querySelectedLernpfad,
      variables,
      context,
    });
    return { selectedLernpfad, isFetching };
  },
};
</script>
