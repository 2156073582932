<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'queryUserSession',
  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'network-only',
    });

    const queryUserSession = gql`
      query {
        me {  
          userData {
            data {
              attributes {
                session  
              }
            }
          }
        }
      }
    `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: queryUserSessionEx } = useQuery({
      query: queryUserSession,
      context,
      fetchOnMount: false,
    });

    store.commit('setStrapiExecutionHandler', { item: 'queryUserSessionEx', result: queryUserSessionEx });
  },
};
</script>
