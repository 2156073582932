<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'updateUserSessionMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const updateUserSessionMutation = gql`
      mutation($userDataId: ID!, $session: String!) {
        updateUserData(id: $userDataId, data: { session: $session }) {
          data {
            attributes {
              session
            }
          }
        }
      }
      `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: updateUserSession } = useMutation(updateUserSessionMutation, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'updateUserSession', result: updateUserSession });
  },
};
</script>
