<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-new-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
  </svg>
</template>

<script>
export default {
  name: 'glassesSVG',
};
</script>
