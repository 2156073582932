<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'updateUserDataFcmMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const updateUserDataFcmMutation = gql`
      mutation($userDataId: ID!, $fcm: String!) {
        updateUserData(id: $userDataId, data: { fcm: $fcm }) {
          data {
            attributes {
              fcm
            }
          }
        }
      }
      `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: updateUserDataFcm } = useMutation(updateUserDataFcmMutation, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'updateUserDataFcm', result: updateUserDataFcm });
  },
};
</script>
