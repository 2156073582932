<script>
export default {
  name: 'colors',

  computed: {
    currentCourseColor() {
      return this.$store.state.settings.currentCourseColor;
    },
    currentCourseColor2() {
      return this.$store.state.settings.currentCourseColor2;
    },
    currentCourseColor3() {
      return this.$store.state.settings.currentCourseColor3;
    },
  },

};
</script>
