<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out h-6 w-6" :class="{'transition duration-300 ease-in-out text-new-disabled' : color === 'grey', 'transition duration-300 ease-in-out text-new-black' : color === 'black'}" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
    <path stroke-linecap="round" stroke-linejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
  </svg>
</template>

<script>
export default {
  name: 'penSVG',
  props: ['color'],
};
</script>
