<script>
import md5 from 'md5';

export default {
  name: 'auth',

  computed: {
    isAuthenticated() {
      return this.$store.state.auth.isAuthenticated;
    },
    updateUserSession() {
      return this.$store.state.updateUserSession;
    },
    jwt() {
      return this.$store.state.auth.jwt;
    },
    queryUserSessionEx() {
      return this.$store.state.queryUserSessionEx;
    },
    queryMeEx() {
      return this.$store.state.queryMeEx;
    },
    queryMyBattlesEx() {
      return this.$store.state.queryMyBattlesEx;
    },
    session() {
      return this.$store.state.auth.session;
    },
    updateUserDataFirstLogin() {
      return this.$store.state.updateUserDataFirstLogin;
    },
    updateActiveCourses() {
      return this.$store.state.updateActiveCourses;
    },
    dataPolicyVersion() {
      return this.$store.state.auth.dataPolicy.data.attributes.Version;
    },
    queryServerTimeEx() {
      return this.$store.state.queryServerTimeEx;
    },
  },

  watch: {
    isAuthenticated() {
      if (this.isAuthenticated) {
        this.saveJwt();
        this.queryMeEx().then((result) => {
          if (result.error) {
            console.log('here comes the error');
            console.log(result.error.message);
            this.$store.commit('setLoading', false);
            this.logout();
          } else {
            const me = result.data?.me;
            const userData = me?.userData?.data?.attributes;
            const userDataId = me?.userData?.data?.id;
            this.getFCM();
            this.updateSession(me);
            this.setActiveCourses(me);
            this.getCurrentSemester(me);
            this.setCurrentCourse(me);
            this.queryMyBattlesEx();
            if (userData?.firstLogin) {
              const userName = `${userData.Vorname} ${userData.Nachname}`;
              this.updateUserDataFirstLogin({ userDataId, nickname: userName }).then(() => {
                this.queryMeEx().then(() => {
                  this.$router.replace({ name: 'onboarding' });
                  this.$store.commit('setLoading', false);
                });
              });
            } else {
              this.checkDataPolicyChange(me);
              this.$store.commit('setLoading', false);
            }
          }
        });
      } else {
        this.$router.push({ name: 'login' });
      }
    },
  },

  methods: {

    checkDataPolicyChange(me) {
      if (me.userData?.data?.attributes?.datenschutzVersion !== this.dataPolicyVersion) {
        this.$router.replace({ name: 'onboarding' });
      } else {
        this.$router.replace({ name: 'dashboard' });
      }
    },

    getCurrentSemester(me) {
      this.queryServerTimeEx()
        .then((result) => {
          if (result.error) {
            console.log(result.error.message);
          } else {
            const serverTime = Date.parse(result.data.getServerTime.serverTime);
            const semester = me.klassen?.data[0]?.attributes?.Semester;
            semester.forEach((sem) => {
              const semesterStart = Date.parse(sem.Start);
              const semesterEnde = Date.parse(sem.Ende);
              console.log(serverTime >= semesterStart);
              console.log(serverTime <= semesterEnde);
              console.log(sem.Semester);
              if (serverTime >= semesterStart && serverTime <= semesterEnde && sem.Semester) this.$store.commit('setCurrentSemester', sem.Semester);
            });
          }
        });
    },

    setCurrentCourse(me) {
      const kurse = me.klassen?.data[0]?.attributes?.Zuordnung;
      const kursIndex = kurse?.findIndex((zuordnung) => zuordnung.Kurs);
      if (kurse && kursIndex !== -1) {
        this.$store.commit('setCurrentCourse', {
          class: me.klassen?.data[0]?.id,
          course: kurse[kursIndex]?.Kurs?.data?.attributes,
          index: kursIndex,
          id: kurse[kursIndex]?.Kurs?.data?.id,
        });
      } else {
        this.$store.commit('toggleInfoOverlay', { value: true, index: 2 });
        this.loading = false;
        this.logout();
      }
    },

    setActiveCourses(me) {
      const activeCourses = [];
      const userDataId = me.userData?.data?.id;
      me.klassen.data.forEach((klasse) => {
        const kursIndex = klasse?.attributes?.Zuordnung.findIndex((zuordnung) => zuordnung.Kurs);
        if (kursIndex !== '-1' && klasse?.attributes?.Zuordnung[kursIndex]?.Kurs?.data?.id) {
          activeCourses.push(`'${klasse.attributes.Zuordnung[kursIndex].Kurs.data.id}'`);
        }
      });
      this.updateActiveCourses({ id: userDataId, activeCourseIds: activeCourses.toString() });
    },

    logout() {
      this.$store.dispatch('logoutUser');
    },

    saveJwt() {
      if (window.localStorage.rememberMe && window.localStorage.rememberMe !== 'false') {
        window.localStorage.setItem('app', this.jwt);
      } else {
        window.localStorage.removeItem('rememberMe');
      }
    },

    updateSession(me) {
      const session = md5(Date.now());
      const userDataId = me?.userData?.data?.id;
      this.updateUserSession({ userDataId, session }).then((result) => {
        if (result.error) {
          console.log(result.error.message);
        } else if (result.data?.updateUserData?.data?.attributes?.session) {
          this.$store.commit('setSession', result.data.updateUserData.data.attributes.session);
          this.startWatchingSession();
        }
      });
    },

    startWatchingSession() {
      if (this.isAuthenticated) {
        setTimeout(() => {
          this.queryUserSessionEx().then((result) => {
            if (result.data?.me?.userData?.data?.attributes?.session) {
              const fetchedSession = result.data.me.userData.data.attributes.session;
              if (fetchedSession === this.session) {
                this.startWatchingSession();
              } else {
                this.logout();
              }
            }
          });
        }, 30000);
      }
    },
  },

};
</script>
