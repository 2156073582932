const getDefaultState = () => ({
  id: null,
  preQuestions: null,
  preLimitedQuestions: null,
  prePfadaufgaben: null,
  mcExams: null,
  mcSubjects: null,
  preLimitedPfadaufgaben: null,
  isFetchingPreQuestions: false,
  isFetchingQuestions: false,
  isFetchingPrePfadaufgaben: false,
  isFetchingPfadaufgaben: false,
  preTags: null,
  tags: null,
  settings: {
    review: false,
  },
});


export default {
  state: getDefaultState(),
  getters: {
    currentProgress: (state) => (questionId) => {
      const progressIndex = state.currentTraining.progress.findIndex((question) => question.questionId === questionId);
      return state.currentTraining.progress[progressIndex];
    },
    currentlySelectedAnswers: (state) => (questionId) => {
      const progressIndex = state.currentTraining.progress.findIndex((question) => question.questionId === questionId);
      return state.currentTraining.progress[progressIndex].selectedAnswersIds.length;
    },
    shouldBeSelectedAnswers: (state) => (questionId) => {
      let count = 0;
      const questionIndex = state.currentTraining.questionData.findIndex((question) => question.id === questionId);
      state.currentTraining.questionData[questionIndex].attributes.Antwort.forEach((antwort) => {
        if (antwort.Richtig) {
          count += 1;
        }
      });
      return count;
    },
  },
  mutations: {

    resetTrainingState(state) {
      Object.assign(state, getDefaultState());
    },

    setMcSubjects(state, subjects) {
      state.mcSubjects = subjects;
    },

    setMcExams(state, exams) {
      state.mcExams = exams;
    },

    setFetchingIndicator(state, payload) {
      state[`isFetching${payload.key}`] = payload.value;
    },

    finishTraining(state) {
      state.currentTraining.finished = true;
    },

    loadTraining(state, payload) {
      const { settings, currentTraining, id } = payload;
      state.settings = settings;
      state.currentTraining = currentTraining;
      state.id = id;
    },

    loadRound(state, round) {
      state.currentTraining = round;
    },

    startTraining(state, { type, finished }) {
      state.currentTraining.type = type;
      state.currentTraining.finished = finished || false;
      state.currentTraining.progress = [];
      if (type === 'battle') state.settings.review = false;
      if (type === 'mcTraining' || type === 'battle') {
        state.currentTraining.questionIds.forEach((id) => {
          state.currentTraining.progress.push({
            questionId: id,
            selectedAnswersIds: [],
            correctAnswersIds: [],
            submitted: false,
            result: null,
          });
        });
      } else {
        state.currentTraining.pfadaufgabenIds.forEach((id) => {
          state.currentTraining.progress.push({
            pfadaufgabeId: id,
            submitted: false,
            result: null,
            selectedAnswers: [],
            selectedListAnswers: [],
            selectedList: 0,
            inputText: '',
            leftAnswers: [],
            solvedAnswers: 0,
          });
        });
      }
    },

    setQuestions(state, data) {
      const shuffledQuestions = data.mcFragens.data;
      shuffledQuestions.map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
      shuffledQuestions.forEach((question, index) => {
        const shuffledAnswers = question.attributes.Antwort
          .map((value) => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value);
        shuffledQuestions[index].attributes.Antwort = shuffledAnswers;
      });
      state.currentTraining.questionData = [];
      state.currentTraining.questionIds = [];
      const total = shuffledQuestions.length;
      if (state?.currentTraining?.type === 'Battle' && total % 4 !== 0) {
        shuffledQuestions.splice(0, total % 4);
      }
      shuffledQuestions.forEach((shuffeldQuestion) => {
        state.currentTraining.questionData.push(shuffeldQuestion);
        state.currentTraining.questionIds.push(shuffeldQuestion.id);
      });
    },

    setPfadaufgaben(state, data) {
      const shuffledPfadaufgaben = data.pfadaufgabens.data
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
      state.currentTraining.pfadaufgabenData = [];
      state.currentTraining.pfadaufgabenIds = [];
      shuffledPfadaufgaben.forEach((shuffeldPfadaufgabe) => {
        state.currentTraining.pfadaufgabenData.push(shuffeldPfadaufgabe);
        state.currentTraining.pfadaufgabenIds.push(shuffeldPfadaufgabe.id);
      });
    },

    setPreQuestions(state, preQuestions) {
      const questionsIds = [];
      const tagIds = [];
      preQuestions.mcFragens.data.forEach((question) => {
        questionsIds.push(question.id);
        question.attributes.tags.data.forEach((tag) => {
          if (!tagIds.includes(tag.id)) {
            tagIds.push(tag.id);
          }
        });
      });
      state.preTags = tagIds;
      state.preQuestions = questionsIds;
    },

    setPrePfadaufgaben(state, prePfadaufgaben) {
      const pfadaufgabenIds = [];
      const tagIds = [];
      prePfadaufgaben.pfadaufgabens.data.forEach((pfadaufgabe) => {
        // eslint-disable-next-line no-underscore-dangle
        if (pfadaufgabe.attributes.Aufgabentyp[0].__typename !== 'ComponentAufgabenMcFrage') {
          pfadaufgabenIds.push(pfadaufgabe.id);
          pfadaufgabe.attributes.tags.data.forEach((tag) => {
            if (!tagIds.includes(tag.id)) {
              tagIds.push(tag.id);
            }
          });
        }
      });
      state.preTags = tagIds;
      state.prePfadaufgaben = pfadaufgabenIds;
    },

    setPreLimitedQuestions(state, questions) {
      state.preLimitedQuestions = questions;
    },

    setPreLimitedPfadaufgaben(state, pfadaufgaben) {
      state.preLimitedPfadaufgaben = pfadaufgaben;
    },

    resetTagFilter(state) {
      state.settings.tags = [];
      state.settings.tagsID = [];
    },

    addAnswer(state, payload) {
      const { answerID, questionID } = payload;
      const progressIndex = state.currentTraining.progress.findIndex((question) => question.questionId === questionID);
      const selectedAnswers = state.currentTraining.progress[progressIndex].selectedAnswersIds;
      if (selectedAnswers.includes(answerID)) {
        const index = selectedAnswers.indexOf(answerID);
        selectedAnswers.splice(index, 1);
      } else {
        selectedAnswers.push(answerID);
      }
    },

    setQuestionResult(state, payload) {
      const { result, questionID, correctAnswersIds } = payload;
      const progressIndex = state.currentTraining.progress.findIndex((question) => question.questionId === questionID);
      state.currentTraining.progress[progressIndex].result = result;
      state.currentTraining.progress[progressIndex].correctAnswersIds = correctAnswersIds;
      state.currentTraining.progress[progressIndex].submitted = true;
    },

    setPfadaufgabeResult(state, {
      pfadaufgabeId, submitted, result, selectedAnswers, selectedListAnswers, selectedList, inputText, leftAnswers, solvedAnswers,
    }) {
      const progressIndex = state.currentTraining.progress.findIndex((pfadaufgabe) => pfadaufgabe.pfadaufgabeId === pfadaufgabeId);
      state.currentTraining.progress[progressIndex].submitted = submitted;
      state.currentTraining.progress[progressIndex].result = result;
      state.currentTraining.progress[progressIndex].selectedAnswers = selectedAnswers;
      state.currentTraining.progress[progressIndex].selectedListAnswers = selectedListAnswers;
      state.currentTraining.progress[progressIndex].selectedList = selectedList;
      state.currentTraining.progress[progressIndex].inputText = inputText;
      state.currentTraining.progress[progressIndex].leftAnswers = leftAnswers;
      state.currentTraining.progress[progressIndex].solvedAnswers = solvedAnswers;
    },

    totalCorrect(state) {
      state.currentTraining.totalCorrect += 1;
    },

  },
  actions: {
    replayTraining({ state, commit }, payload) {
      const { settings, currentTraining } = payload;
      state.settings = settings;
      state.settings.review = false;
      state.currentTraining.pfadaufgabenIds = currentTraining.pfadaufgabenIds;
      state.currentTraining.pfadaufgabenData = currentTraining.pfadaufgabenData;
      state.currentTraining.questionIds = currentTraining.questionIds;
      state.currentTraining.questionData = currentTraining.questionData;
      commit('startTraining', { type: currentTraining.type });
    },

    resetTraining({ state, rootState }, { settings, type }) {
      state.id = null;
      state.currentTraining = {
        totalCorrect: 0,
        type,
        finished: null,
        questionIds: [],
        questionData: [],
        pfadaufgabenData: [],
        pfadaufgabenIds: [],
        progress: [],
      };
      if (settings) {
        state.settings = settings;
      } else {
        state.settings = {
          battlePlayerId: null,
          battlePlayerName: null,
          publicBattleId: null,
          subjects: [],
          subjectsID: [],
          lists: [],
          listsID: [],
          tags: [],
          tagsID: [],
          exams: [],
          examsID: [],
          stats: [],
          wrongPlayedID: [],
          recentPlayedID: [],
          totalPlayedID: [],
          time: false,
          limitedQuestions: false,
          comments: false,
          exam: false,
          minutes: null,
          questions: null,
          filter: {
            kurs: { id: { eq: rootState.settings.currentCourseID } },
            ...(type !== 'Pfadaufgabe' && { pruefungen: { availableForTraining: { ne: false } } }),
          },
          limitFilter: {
            limit: 20,
          },
          review: false,
        };
      }
    },

    saveTraining({ state, rootState }, { type, remainingTime }) {
      const userDataId = rootState.profile.me.userData.data.id;
      const { recentTrainings } = rootState.profile.me.userData.data.attributes;
      const recentTraining = {
        ...(state.id !== null && { id: state.id }),
        courseId: parseInt(rootState.settings.currentCourseID, 10),
        settings: state.settings,
        currentTraining: state.currentTraining,
        type,
      };

      if (remainingTime) {
        recentTraining.currentTraining.remainingTime = remainingTime;
      }

      // Find existing
      const trainingIndex = recentTrainings.findIndex((training) => training.id === state.id);
      if (trainingIndex !== -1) {
        recentTrainings.splice(trainingIndex, 1);
      }

      // Check Limit
      let count = 0;
      recentTrainings.forEach((training) => {
        if (training.courseId === parseInt(rootState.settings.currentCourseID, 10) && training.type === type) {
          count += 1;
        }
      });

      if (count >= process.env.VUE_APP_MAX_RECENT_TRAININGS) {
        recentTrainings.splice(0, 1);
      }

      // Finally Push
      recentTrainings.push(recentTraining);

      rootState.updateUserDataRecentTraining({ userDataId, recentTrainings }).then((result) => {
        if (result.error) {
          console.log(result.error.message);
        } else {
          rootState.queryMeEx();
        }
      });
    },

  },
};
