<template>
  <div class="relative">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-new-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
    </svg>
    <div v-if="unreadNotifications || newNotification" class="absolute rounded-full -bottom-1 -right-1 w-4 h-4 bg-red-500 border-2 border-new-yellow"></div>
  </div>
</template>

<script>
export default {
  name: 'bellSVG',

  computed: {
    notifications() {
      return this.$store.state.profile?.me?.userData?.data?.attributes?.notifications;
    },
    unreadNotifications() {
      if (this.notifications.length > 0 && this.notifications.some((e) => !e.read)) {
        return true;
      }
      return false;
    },
    newNotification() {
      return this.$store.state.settings.newNotification;
    },
  },
};
</script>
