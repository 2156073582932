<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'queryMe',
  watch: {
    me() {
      this.$store.commit('setStrapiResult', { vuexModule: 'profile', state: 'me', data: this.me.me });
    },
  },
  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
      cachePolicy: 'network-only',
    });

    const queryMe = gql`
      query {
        me {
          username
          email
          id
          lernpfade {
            data {
              id
              attributes {
                kurse {
                  data {
                    id
                  }
                }
                Bezeichnung
                Kurzbeschreibung
                lernpfad_filter {
                  data {
                    id
                    attributes {
                      Bezeichnung
                    }
                  }
                }
                lernspots(pagination: {limit: 500}) {
                  id
                }
                Vorschaubild {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          klassen {
            data {
              id
              attributes {
              publishedAt
                Bezeichnung
                Semester {
                  Start
                  Ende
                  Semester
                }
                Zuordnung {
                  ... on ComponentKlassenKurs {
                    Lernpfade(
                      pagination: {limit: 999}
                      sort: "Lernpfad.Reihenfolge"
                    ) {
                      Semester
                      Lernpfad {
                        data {
                          id
                          attributes {
                            Bezeichnung
                            Kurzbeschreibung
                            lernpfad_filter {
                              data {
                                id
                                attributes {
                                  Bezeichnung
                                }
                              }
                            }
                            lernspots(pagination: {limit: 500}) {
                              id
                            }
                            Vorschaubild {
                              data {
                                attributes {
                                  url
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    Kurs {
                      data {
                        id
                        attributes {
                          glossar {
                            data {
                              id
                            }
                          }
                          Bezeichnung
                          Farbcode
                          Farbcode2
                          Farbcode3
                        }
                      }
                    }
                  }
                  ... on ComponentKlassenPruefung {
                    pruefung(
                      filters: {pruefungen: {publishedAt: { ne: null }}}
                      pagination: {limit: 500})  {
                      id
                      pruefungen {
                        data {
                          id
                          attributes {
                            Index
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          userData {
            data {
              id
              attributes {
                currentStreak
                longestStreak
                streakJoker
                onboardingCompleted
                Vorname
                Nachname
                nickname
                avatar {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                session
                datenschutzVersion
                fcm
                firstLogin
                myLists {
                  id
                  mcFragen
                  lernpfade
                  pfadaufgaben
                  Bezeichnung
                }
                recentTrainings {
                  id
                  courseId
                  settings
                  currentTraining
                  type
                }
                wrongPlayedQuestions
                recentPlayedQuestions
                totalPlayedQuestions
                wrongPlayedExercise
                recentPlayedExercise
                totalPlayedExercises
                lernpfadProgress
                lastLernpfade
                examProgress(pagination: { limit: 500 }) {
                  id
                  finished
                  passed
                  started
                  progress
                  totalCorrect
                  pruefungen {
                    data {
                      id
                      attributes {
                        Index
                      }
                    }
                  }
                }
                points
                activeCourseIds
                notifications(pagination: { limit: 500 }) {
                  time
                  title
                  body
                  route
                  read
                  courseId
                }
              }
            }
          }
        }
      }
    `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const variables = reactive({
      today: computed(() => store.state.profile.today),
    });

    const { execute: queryMeEx, data: me } = useQuery({
      query: queryMe,
      context,
      variables,
      fetchOnMount: false,
    });
    store.commit('setStrapiExecutionHandler', { item: 'queryMeEx', result: queryMeEx });
    return { me };
  },
};
</script>
