<script>
export default {
  name: 'iosVibrations',

  data() {
    return {
      iosVibrations: ['vibrateLight', 'vibrateMedium', 'vibrateHeavy', 'vibrateSuccess', 'vibrateWarning', 'vibrateError'],
      androidVibrations: ['vibrateClick', 'vibrateDoubleClick', 'vibrateTick', 'vibrateHeavy'],
    };
  },

  methods: {
    iosVibrate(index) {
      const intensity = this.iosVibrations[index];
      if (window?.webkit?.messageHandlers?.[intensity]) {
        window.webkit.messageHandlers[intensity].postMessage('vibrating..');
      }
      if (window.Android) {
        let androidIndex = 0;
        if (index === 5) androidIndex = 1;
        const androidIntensity = this.androidVibrations[androidIndex];
        if (window?.Android[androidIntensity]) window.Android[androidIntensity]('vibrating..');
      }
    },
  },
};
</script>
