<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'updateUserDataListMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const updateUserDataListMutation = gql`
      mutation($userDataId: ID!, $list: [ComponentTrainingListInput]) {
        updateUserData(id: $userDataId, data: { myLists: $list }) {
          data {
            id
          }
        }
      }
      `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: updateUserDataList } = useMutation(updateUserDataListMutation, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'updateUserDataList', result: updateUserDataList });
  },
};
</script>
